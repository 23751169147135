import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import * as React from "react";
import { hydrateRoot } from "react-dom/client";

if ((window as any).ENV && !(window as any).ENV.TEST) {
  Sentry.init({
    dsn: (window as any).ENV.SENTRY_DSN,
    environment: (window as any).ENV.SENTRY_ENVIRONMENT,
    release: (window as any).ENV.HEROKU_SLUG_COMMIT || "development",
    tracesSampleRate: 1,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(
          React.useEffect,
          useLocation,
          useMatches
        ),
      }),
    ],
  });
}

const hydrate = () => {
  React.startTransition(() => {
    hydrateRoot(
      document,
      <React.StrictMode>
        <RemixBrowser />
      </React.StrictMode>
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
